/* eslint-disable react/prop-types,react/display-name */
import React from 'react';
import { Box, Heading, Text } from '@fast-ai/ui-components';
import LinkButton from '@fast-ai/ui/LinkButton';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntlContext } from 'gatsby-theme-fast-ai-sidebar/src/intl';

import plkLogo from '../../content/assets/plk-logo.svg';
import plkStudy from '../../content/assets/plk-case-study.pdf';
import plkStudyEn from '../../content/assets/plk-case-study.en.pdf';
import { DefaultLayout } from '../layout';
import { CompaniesBrands, Seo } from '../ui';
import { Link, RichFormattedMessage, useTranslateMessage } from '../intl';
import m from '../intl/messages/pageReferences';
import ReferencesSection from '../view/landing/ReferencesSection';
import { ContactSection } from '../view/landing';

const styles = {
	row: {
		flexWrap: 'wrap',
		textAlign: 'left',
	},
	heroHeading: {
		fontSize: [6, 7, '3vw', 8],
		mb: [2, 2, 3, 3],
		mx: ['auto', 'auto', 0],
		maxWidth: '40rem',
		position: 'relative',
	},
	perex: {
		maxWidth: '50rem',
		mb: [2, 2, 3, 3],
		fontSize: [2, 2, 2, 2],
		mx: ['auto', 'auto', 0],
	},
	bigButton: {
		py: [2, 3],
		px: [3, 4],
	},
};

const Hero = () => {
	const { language: currentLanguage } = useIntlContext();

	return (
		<Box
			as="section"
			sx={{
				px: t => t.grid.gutters,
				py: [4, 4, 6],
				mx: 'auto',
				flexGrow: 1,
				position: 'relative',
				width: '100%',
				maxWidth: ['100%', '100%', '70em', '82em'],
				display: 'flex',
				flexDirection: ['column', 'column', 'row'],
				alignItems: 'center',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexGrow: 1,
					flexShrink: 0,
					alignItems: 'center',
					flexWrap: 'wrap',
					textAlign: ['center', 'center', 'left'],
					zIndex: 1,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						width: ['100%', '100%', '50%'],
					}}
				>
					<Heading sx={styles.heroHeading}>
						<RichFormattedMessage {...m.plkHeading} />
					</Heading>
					<Text as="p" sx={styles.perex}>
						<RichFormattedMessage {...m.plkPerex} />
					</Text>

					<Box sx={styles.actions}>
						<LinkButton
							as={Link}
							variant="primary"
							to={currentLanguage === 'en' ? plkStudyEn : plkStudy}
							sx={styles.bigButton}
						>
							<Text sx={{ color: 'inherit' }}>
								<RichFormattedMessage {...m.plkCta} />
							</Text>
						</LinkButton>
					</Box>
				</Box>

				<Box
					sx={{
						height: 'auto',
						py: [4, 4, 0],
						width: ['100%', '100%', '50%'],
						maxWidth: ['100%', '550px', '500px', '550px'],
						mx: 'auto',
						pointerEvents: 'none',
						zIndex: -1,
						position: 'relative',
					}}
				>
					<StaticImage
						placeholder="blurred"
						src="../../content/assets/plk-zoe.png"
						alt="Zoe & Pilulka"
						layout="fullWidth"
					/>
					<Box
						as="img"
						sx={{
							width: '250px',
							position: ['absolute'],
							bottom: [0, 0, '5%'],
							p: [4, 4, 0],
							right: [0, 0, '50px'],
							display: 'block',
						}}
						alt="Pilulka.cz - logo"
						src={plkLogo}
					/>
				</Box>
			</Box>
		</Box>
	);
};

const ReferencesPage = ({ location, ...rest }) => {
	const translate = useTranslateMessage();

	return (
		<DefaultLayout {...rest} location={location}>
			<Seo title={translate(m.title)} />

			<Hero />

			<ReferencesSection variant="stripesSecondary" />

			<Box sx={{ py: 4 }}>
				<CompaniesBrands hideReferencesLink location={location} />
			</Box>

			<ContactSection variant="stripesSecondary" />
		</DefaultLayout>
	);
};

export default ReferencesPage;
