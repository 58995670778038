import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	title: {
		id: 'page.references.title',
		defaultMessage: 'References',
	},
	plk: {
		id: 'page.references.plk',
		defaultMessage: 'Pilulka',
	},
	plkHeading: {
		id: 'page.references.plkHeading',

		defaultMessage:
			'How hyper‑personalization{br} <strong>increased sales</strong> for the largest online pharmacy?',
	},
	plkPerex: {
		id: 'page.references.plkPerex',

		defaultMessage: 'Take a look at the case study for Pilulka.cz.',
	},
	plkCta: {
		id: 'page.references.plkCta',
		defaultMessage: 'Read case study',
	},
});
